.customNodeBody {
  max-width: 200px;
  min-width: 200px;
  height: 100px;
  min-height: 40px;
  border: 1px solid #4b83ff;
  position: relative;
  overflow: visible;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  background: #18181b;
  color: white;
  /* white-space: nowrap; */
  cursor: grab;
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */
  max-width: 238px;
  /* text-decoration: justify; */
}
.changeFlowNodeBody {
  width: 200px;
  height: fit-content;
  min-height: 40px;
  border: 1px solid #607d8b;
  position: relative;
  overflow: visible;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  background: #607d8b;
  color: white;
  cursor: grab;
}

.customNodeText {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  color: white;
}

.customNodeBodyInput {
  width: 200px;
  height: 80px;
  border: 1px solid #ffa54c;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: #18181b;
  color: white;
  cursor: grab;
}

.glow {
  box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.7);
}

.startNodeBody {
  width: 180px;
  height: 40px;
  border: 1px solid #4b83ff;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: #5b5bf7;
  color: white;
  cursor: grab;
}
.queueNodeBody {
  width: 200px;
  height: 60px;
  border: 1px solid #607d8b;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: #607d8b;
  color: white;
  cursor: grab;
}
.dayNodeBody {
  width: 200px;
  height: "fit-content";
  border: 1px solid #4b83ff;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: #18181b;
  color: white;
  cursor: grab;
  padding: 10px;
}

.customNodeBodyConditional {
  width: 250px;
  height: fit-content;
  border: 1px solid purple;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: #18181b;
  color: white;
  cursor: grab;
}
.customNode:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  height: 20px;
  width: 40px;
  transform: translate(-50%, 0);
  background: #d6d5e6;
  z-index: 1000;
  line-height: 1;
  border-radius: 4px;
  color: #fff;
  font-size: 9px;
  border: 2px solid #222138;
}

div.customHandle {
  width: 100%;
  height: 100%;
  background: blue;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  transform: none;
  border: none;
  opacity: 0;
}

.context-menu {
  background: white;
  border-style: solid;
  box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
  z-index: 10;
  max-height: 300px;
  max-width: 300px;
}

.context-menu button {
  border: none;
  display: block;
  padding: 0.5em;
  text-align: left;
  width: 100%;
}

.context-menu button:hover {
  background: white;
}

.range-selector {
  width: 300px;
  height: 20px;
  background-color: #ddd;
  position: relative;
  cursor: pointer;
}

.selected-range {
  height: 100%;
  width: 0;
  background-color: #4caf50;
  position: absolute;
}
